import client from '../utils/axios'
import { getQueryString } from '../utils/url'

export const getShippingAddresses = (queryParams) => {
  const query = getQueryString(queryParams)
  return client.get('/api/shipping-addresses?' + query)
}

export const getSalesUseTaxForms = (queryParams) => {
  const query = getQueryString(queryParams)
  return client.get('/api/sales-use-tax-form?' + query)
}

export const getShippingAddress = (shippingAddressId) => {
  return client.get(`/api/shipping-addresses/${shippingAddressId}`)
}

export const addShippingAddress = (payload) => {
  return client.post('/api/shipping-addresses/', payload)
}

export const updateShippingAddress = ({ shippingAddressId, payload }) => {
  return client.put(`/api/shipping-addresses/${shippingAddressId}`, payload)
}

export const deleteShippingAddress = (shippingAddressId) => {
  return client.delete(`/api/shipping-addresses/${shippingAddressId}`)
}

export const getParents = () => {
  return client.get('/api/users/parents')
}

export const getCustomers = (queryParams = {}) => {
  const query = getQueryString(queryParams)
  return client.get('/api/users/customers?' + query)
}

export const getOrders = (query) => {
  const queryParams = { requestedBy: 'website', fields: 'orderDate,purchaseOrders', ...query }
  const qs = getQueryString(queryParams)
  return client.get('/api/orders?' + qs)
}

export const getOrderById = (id, query) => {
  const queryParams = { fields: 'orderDate,purchaseOrders', ...query }
  const qs = getQueryString(queryParams)
  return client.get(`/api/orders/${id}?` + qs)
}

export const assignRemoveChildAccounts = (payload) => {
  return client.post('/api/contacts/assign-contacts', payload)
}

export const getParentTerms = (parentId) => {
  return client.get(`/api/parentAccount/${parentId}/terms`)
}

export const getBillingAddress = (parentId) => {
  return client.get(`/api/billing-addresses?parentId=${parentId}`)
}

export const updateBillingAddress = (payload) => {
  return client.put('/api/billing-addresses', payload)
}

export const verifyWebLink = ({ id }) => {
  return client.post('/api/parentAccount/verify-web-link', { webLinkToken: id })
}

export const generateWebLink = async(payload) => {
  return client.post('/api/parentAccount/generate-web-link', payload)
}

export const toggleWebLink = async(payload) => {
  return client.patch('/api/parentAccount/toggle-web-link', payload)
}