
const isGuestLink = window.top.location.pathname.startsWith('/preview')

export const setToken = ({ accessToken, refreshToken }) => {
    if(isGuestLink){
        sessionStorage.setItem('accessToken', accessToken)
        sessionStorage.setItem('refreshToken', refreshToken)
    }else{
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)
    }
}

export const getToken = () => {
    let accessToken = null, refreshToken = null
    if(isGuestLink){
        accessToken = sessionStorage.getItem('accessToken')
        refreshToken = sessionStorage.getItem('refreshToken')
    }else{
        accessToken = localStorage.getItem('accessToken')
        refreshToken = localStorage.getItem('refreshToken')
    }
    return { accessToken, refreshToken }
}

export const removeToken = () => {
    if(!isGuestLink){
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
    }
}