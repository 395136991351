import { useState, useEffect } from 'react'
import { createContext, useContext } from 'react'
import { useAuth } from './AuthContext'
import useCustomerStore from '../store/useCustomerStore'
import { useCartQuery } from '../react-query/cart'
import { useShippingAddressesQuery } from '../react-query/account'
import { useSizeScalesQuery } from '../react-query/templates'

const initialContextValue = {
  parentId: null,
  setParentId: () => {},
}

const parentContext = createContext(initialContextValue)

const ParentProvider = ({ children }) => {
  const { user } = useAuth()
  const [parentId, setParentId] = useState(null)
  const fetchCustomers = useCustomerStore((state) => state.fetch)
  const { refetch: fetchCartItem } = useCartQuery(parentId, { enabled: false })
  const { refetch: fetchSizeScales } = useSizeScalesQuery(parentId, { enabled: false })
  const { refetch: fetchShippingAddresses } = useShippingAddressesQuery(
    { parentId },
    {
      enabled: false,
    },
  )

  useEffect(() => {
    if (parentId) {
      fetchCustomers(parentId)
    }
    if(parentId && user?.type !== 'Guest'){
      fetchCartItem()
      fetchSizeScales()
      fetchShippingAddresses()
    }
  }, [parentId])

  useEffect(() => {
    if(user?.type === 'Guest') setParentId(user.parentId)
  }, [user])

  return (
    <parentContext.Provider value={{ parentId, setParentId }}>{children}</parentContext.Provider>
  )
}

export const useParent = () => useContext(parentContext)

export default ParentProvider
