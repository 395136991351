import axios from 'axios'
import { BASE_URL } from '../config'
import * as token from '../utils/token'

const client = axios.create({
  baseURL: BASE_URL,
})

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token) => {
  failedQueue.forEach(prom => {
    if (token) {
      prom.resolve(token)
    } else {
      prom.reject(error)
    }
  })
  failedQueue = []
}

// Function to get a new access token using the refresh token
async function getNewTokens() {
  try {
    const data = await axios
      .post(`${BASE_URL}/api/users/token`, { refreshToken: token.getToken().refreshToken })
      .then((response) => response.data)
    token.setToken(data)
    return data?.accessToken
  } catch (error) {
    throw new Error('Unable to refresh token')
  }
}

client.interceptors.request.use((config) => {
  config.headers.Authorization = token.getToken().accessToken
  return config
})

client.interceptors.response.use(
  (response) => {
    if (response.config.baseURL === BASE_URL) {
      if (response.config.url === '/api/users/change-password') {
        return Promise.resolve({
          ...response.data.data,
          accessToken: JSON.parse(response.config.data).accessToken,
        })
      }
      return response.data.data
    }
    return response
  },
  (error) => {
    if (error.response) {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          }).then(token => {
            originalRequest.headers.Authorization = token
            return client.request(originalRequest)
          }).catch(err => {
            return Promise.reject(err)
          })
        }

        originalRequest._retry = true
        isRefreshing = true

        return new Promise(function (resolve, reject) {
          getNewTokens().then((token) => {
            originalRequest.headers.Authorization = token
            processQueue(null, token)
            resolve(client.request(originalRequest))
          }).catch((err) => {
            processQueue(err, null)
            reject(err)
          }).finally(() => {
            isRefreshing = false
          })
        })
      }
      if(error.response.status === 403){
        handleDeactivatedWebLink()
      }
      if (error.response.config.baseURL === BASE_URL) {
        if (error?.response.config.url === '/api/users/change-password') {
          return Promise.reject(error.response.data.newPassword || 'Unable to change password')
        }
        return Promise.reject(error.response.data.msg || 'Unknown Error')
      }
    } else if (error.request) {
      return Promise.reject('You are probably offline')
    } else {
      console.log(error)
      return Promise.reject('Something Went Wrong')
    }
  },
)

export default client

function handleDeactivatedWebLink(){
  const webLinkToken = localStorage.getItem('webLinkToken')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('webLinkToken')
  if(!webLinkToken) {
    window.location.pathname = '/'
    return
  }
  window.location.pathname = '/preview/' + webLinkToken
}


