/* eslint-disable max-lines */
/* eslint-disable max-len */
import { useAuth } from '../../../contexts/AuthContext'
import { IMAGES } from '../../../assets/images/images'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import CheckoutModal from '../../../components/CheckoutModal/CheckoutModal'
// AUTH_PATH
import { ACCOUNT_PATH, AUTH_PATH, PAGE_PATH } from '../../../router/paths'
import classNames from 'classnames'
import ParentDropdown from '../../../components/ParentDropDown/ParentDropdown'
import { useQueryClient } from 'react-query'
import { useParent } from '../../../contexts/ParentContext'
import LoginPopup from '../../../sections/auth/login/LoginPopup'

const isGuestLink = window.top.location.pathname.startsWith('/preview')

const Header = () => {
  const { pathname } = useLocation()
  const overlayRef = useRef(null)
  const queryClient = useQueryClient()
  const { logout, isAuthenticated: isAfterLogin, user } = useAuth()
  const { parentId } = useParent()
  const [showCart, setShowCart] = useState(false)
  const [isMenu1Open, setIsMenu1Open] = useState(false)
  const [isMenu2Open, setIsMenu2Open] = useState(true)
  const [showOverlay, setShowOverlay] = useState(false)
  const [isLoginOpen, setIsLoginOpen] = useState(false)
  const cartItems = queryClient.getQueryData(['carts', parentId]) || []
  const isActive = (path) => path === pathname

  const handleLoginPopup = () => {
    if(!isGuestLink){
      setIsLoginOpen(!isLoginOpen)
    }
    window.top.location.href = `${window.location.origin}${AUTH_PATH.newUser}`
  }

  const openMenu1 = () => {
    setIsMenu1Open(true)
    setShowOverlay(true)
    setShowCart(false)
  }
  
  const openMenu2 = () => {
    setIsMenu2Open(true)
    setShowOverlay(true)
    setShowCart(false)
  }

  const hideMenuAndOverlay = () => {
    setIsMenu1Open(false)
    setIsMenu2Open(false)
    setShowOverlay(false)
  }

  useEffect(() => {
    // Define the resize handler
    const handleResize = () => {
      if (!overlayRef.current) return
      const mediaQuery = window.matchMedia('(max-width: 991px)')
      //     // Check if the media query is true
      if (mediaQuery.matches) {
        // Then trigger an alert
        overlayRef.current.style.display = 'block'
        return
      }
      overlayRef.current.style.display = 'none'
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <header className='header-cntr'>
        <nav className={`header-box ${isAfterLogin && 'header-after-login'}`}>
          <div className='row align-items-center'>
            <div className={`${isAfterLogin ? 'col-4 col-lg-5' : 'col-3 col-md-5'}`}>
              <nav className='nav-menu-list navbar-expand-lg js-nav-menu-list'>
                <button
                  className='menu-toggle js-menu-toggle d-block d-lg-none'
                  onClick={openMenu1}
                >
                  <i className='fa fa-bars' aria-hidden='true'></i>
                </button>
                {isAfterLogin ? (
                  <ul
                    className={`menu-list navbar-nav js-menu-list ${
                      isMenu1Open ? 'show-menu' : ''
                    }`}
                  >
                    <li className='d-block d-lg-none'>
                      <div className='menu-wrapper'>
                        <h5 className='menu-text'>Menu</h5>
                        <button className='cross-btn' onClick={hideMenuAndOverlay}>
                          <span className='icon-cross'></span>
                        </button>
                      </div>
                    </li>
                    <li
                      className={classNames('nav-item', {
                        active: isActive(ACCOUNT_PATH.list('Tees,Sweats')),
                      })}
                    >
                      <NavLink
                        to={ACCOUNT_PATH.list('Tees,Sweats')}
                        className='nav-link'
                        onClick={hideMenuAndOverlay}
                      >
                        Shop All
                      </NavLink>
                    </li>
                    <li
                      className={classNames('nav-item', {
                        active: isActive(ACCOUNT_PATH.list('Tees')),
                      })}
                    >
                      <NavLink
                        to={ACCOUNT_PATH.list('Tees')}
                        className='nav-link'
                        onClick={hideMenuAndOverlay}
                      >
                        Tees
                      </NavLink>
                    </li>
                    <li
                      className={classNames('nav-item', {
                        active: isActive(ACCOUNT_PATH.list('Sweats')),
                      })}
                    >
                      <NavLink
                        to={ACCOUNT_PATH.list('Sweats')}
                        className='nav-link'
                        onClick={hideMenuAndOverlay}
                      >
                        Sweats
                      </NavLink>
                    </li>
                    <li className={'d-lg-none'}>
                      <div className='header-login after-login'>
                        <ul className='login'>
                          <li className='login-item js-login-item'>
                            <button className='btn rounded-0 item js-login-link-item' disabled>
                              <Link
                                to={ACCOUNT_PATH.account()}
                                className='item js-login-link-item1'
                              >
                                Account
                              </Link>
                            </button>
                          </li>
                          <li className='login-item js-login-item'>
                            <button
                              type={'button'}
                              onClick={() => {
                                setShowCart(true)
                                hideMenuAndOverlay()
                              }}
                              className='btn rounded-0 item js-login-link-item'
                            >
                              My Bag
                              <span
                                className={classNames('text-danger', {
                                  'd-none': !cartItems?.length,
                                })}
                              >
                                ({cartItems.length})
                              </span>
                            </button>
                          </li>
                          <li className='login-item js-login-item'>
                            <button
                              type={'button'}
                              className='btn rounded-0 item js-login-link-item'
                              onClick={() => logout()}
                              disabled={!user?.isUser}
                            >
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <ul
                    className={`menu-list navbar-nav js-menu-list ${
                      isMenu1Open ? 'show-menu' : ''
                    }`}
                  >
                    <li className='nav-item active'>
                      <NavLink to='/' className='nav-link'>
                        Home
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink to={PAGE_PATH.about_us} className='nav-link'>
                        About Us
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink to={PAGE_PATH.contact_us} className='nav-link'>
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
            <div className={`${isAfterLogin ? 'col-8 col-lg-2' : 'col-6 col-md-2'}`}>
              <div className='logo-cart-wrapper'>
                <div className='brand-logo'>
                  <Link to={PAGE_PATH.home}>
                    <img src={IMAGES.Logo} alt={'logo'} />
                  </Link>
                </div>
                <div
                  className='shooping-cart d-block d-lg-none'
                  onClick={() => setShowCart(true)}
                  disabled={!isGuestLink}
                >
                  <span className='material-symbols-outlined shopping-icon'>shopping_bag</span>
                  {!!cartItems?.length && <span className='cart-count'>{cartItems.length}</span>}
                </div>
              </div>
            </div>
            <div className={`${isAfterLogin ? 'col-9 col-lg-5' : 'col-3 col-md-5'}`}>
              <div className='header-login'>
                {isAfterLogin ? (
                  <ul className='login d-none d-lg-flex'>
                    <li className='login-item js-login-item'>
                        <ParentDropdown
                          open={isMenu2Open}
                          onOpen={openMenu2}
                          onHide={hideMenuAndOverlay}
                        />
                    </li>
                    {/* Ï */}
                    <li className='login-item js-login-item'>
                      <button
                        className='btn rounded-0 item js-login-link-item nowrap'
                        disabled={!user?.isUser}
                      >
                        <NavLink to={ACCOUNT_PATH.account()}>Account</NavLink>
                      </button>
                    </li>
                    <li className='login-item js-login-item'>
                      <button
                        type={'button'}
                        className='btn rounded-0 item js-login-link-item nowrap'
                        onClick={() => setShowCart(true)}
                        disabled={isGuestLink}
                      >
                        My Bag{' '}
                        {!isGuestLink && (
                          <span className='text-danger'>
                            {cartItems?.length ? <>({cartItems.length})</> : null}
                          </span>
                        )}
                      </button>
                    </li>
                    {!isGuestLink ? (
                      <li className='login-item js-login-item'>
                        <button
                          type={'button'}
                          className='btn rounded-0 item js-login-link-item'
                          onClick={() => logout()}
                        >
                          Logout
                        </button>
                      </li>
                    ) : (
                      <li className='login-item js-login-item login-popup-wrap'>
                        <button
                          className={`btn item js-login-link-item ${isLoginOpen ? 'active' : ''}`}
                          onClick={handleLoginPopup}
                        >
                          {isLoginOpen ? (
                            <span onClick={handleLoginPopup} className='text-danger'>
                              X
                            </span>
                          ) : (
                            'Login'
                          )}
                        </button>
                        {isLoginOpen && <LoginPopup />}
                      </li>
                    )}
                  </ul>
                ) : (
                  <ul className='login'>
                    <li className='login-item js-login-item login-popup-wrap'>
                      <button
                        className={`btn item js-login-link-item ${isLoginOpen ? 'active' : ''}`}
                        onClick={handleLoginPopup}
                      >
                        {isLoginOpen ? (
                          <span onClick={handleLoginPopup} className='text-danger'>
                            X
                          </span>
                        ) : (
                          'Login'
                        )}
                      </button>
                      {isLoginOpen && <LoginPopup />}
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </nav>
        <CheckoutModal show={user?.isUser && showCart} setShow={setShowCart} />
        <div
          ref={overlayRef}
          className={classNames('overlay', { 'd-none': !showOverlay })}
          onClick={hideMenuAndOverlay}
        ></div>
        <div className='parent-mobile-dropdown d-flex justify-content-center d-lg-none text-center pt-3'>
            <ParentDropdown open={isMenu2Open} onOpen={openMenu2} onHide={hideMenuAndOverlay} />
        </div>
      </header>
    </>
  )
}

export default Header
